import(/* webpackMode: "eager", webpackExports: ["AnchorProvider"] */ "/vercel/path0/node_modules/fumadocs-core/dist/toc.js");
;
import(/* webpackMode: "eager", webpackExports: ["Accordion","Accordions"] */ "/vercel/path0/node_modules/fumadocs-ui/dist/components/accordion.js");
;
import(/* webpackMode: "eager", webpackExports: ["CodeBlock","Pre"] */ "/vercel/path0/node_modules/fumadocs-ui/dist/components/codeblock.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/fumadocs-ui/dist/components/layout/toc-clerk.js");
;
import(/* webpackMode: "eager", webpackExports: ["TocPopover","TocPopoverTrigger","TocPopoverContent","TOCItems","Toc"] */ "/vercel/path0/node_modules/fumadocs-ui/dist/components/layout/toc.js");
;
import(/* webpackMode: "eager", webpackExports: ["Tab","Tabs"] */ "/vercel/path0/node_modules/fumadocs-ui/dist/components/tabs.js");
;
import(/* webpackMode: "eager", webpackExports: ["I18nLabel"] */ "/vercel/path0/node_modules/fumadocs-ui/dist/contexts/i18n.js");
;
import(/* webpackMode: "eager", webpackExports: ["Pre"] */ "/vercel/path0/node_modules/fumadocs-ui/dist/mdx.client.js");
;
import(/* webpackMode: "eager", webpackExports: ["PageBody","TocNav","PageArticle","Breadcrumb","LastUpdate","Footer"] */ "/vercel/path0/node_modules/fumadocs-ui/dist/page.client.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/vercel/path0/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["DocsFooter"] */ "/vercel/path0/src/app/components/docs-footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["EditOnGithub"] */ "/vercel/path0/src/app/components/edit-page.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ScrollToTop"] */ "/vercel/path0/src/app/components/scroll-to-top.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/shared/MarkdownRenderer/components/MarkdownCallout.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["MarkdownEmbed"] */ "/vercel/path0/src/components/shared/MarkdownRenderer/components/MarkdownEmbed.tsx");
