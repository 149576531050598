import(/* webpackMode: "eager", webpackExports: ["LanguageToggle","LanguageToggleText"] */ "/vercel/path0/node_modules/fumadocs-ui/dist/components/layout/language-toggle.js");
;
import(/* webpackMode: "eager", webpackExports: ["NavProvider","Title"] */ "/vercel/path0/node_modules/fumadocs-ui/dist/components/layout/nav.js");
;
import(/* webpackMode: "eager", webpackExports: ["RootToggle"] */ "/vercel/path0/node_modules/fumadocs-ui/dist/components/layout/root-toggle.js");
;
import(/* webpackMode: "eager", webpackExports: ["SearchToggle","LargeSearchToggle"] */ "/vercel/path0/node_modules/fumadocs-ui/dist/components/layout/search-toggle.js");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeToggle"] */ "/vercel/path0/node_modules/fumadocs-ui/dist/components/layout/theme-toggle.js");
;
import(/* webpackMode: "eager", webpackExports: ["StylesProvider"] */ "/vercel/path0/node_modules/fumadocs-ui/dist/contexts/layout.js");
;
import(/* webpackMode: "eager", webpackExports: ["SearchOnly"] */ "/vercel/path0/node_modules/fumadocs-ui/dist/contexts/search.js");
;
import(/* webpackMode: "eager", webpackExports: ["TreeContextProvider"] */ "/vercel/path0/node_modules/fumadocs-ui/dist/contexts/tree.js");
;
import(/* webpackMode: "eager", webpackExports: ["I18nProvider"] */ "/vercel/path0/node_modules/fumadocs-ui/dist/i18n.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/fumadocs-ui/dist/layouts/docs.client.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/fumadocs-ui/dist/layouts/docs/sidebar.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/fumadocs-ui/dist/layouts/links.js");
;
import(/* webpackMode: "eager", webpackExports: ["RootProvider"] */ "/vercel/path0/node_modules/fumadocs-ui/dist/provider.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/vercel/path0/node_modules/next/dist/client/app-dir/link.js");
